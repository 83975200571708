export const environment = {
  production: true,
  clarityProjectId: 'ltx52rmsod',
  GAToken: 'G-QM9YTSHSYK',
  azureStorageContainerName: 'files',
  azureStorageAccountKey: '5Kdk/95RoQuRcFlrhilQyZ2uwYqIJAYsdsx/QIdAbM7E3pGLAhJJ8dyLDHp/WL8jcbHrmQUHGv2N+AStdOIB0A==',
  azureStorageAccountName: 'uploadsmefiles',
  sasToken: 'sp=racwdli&st=2024-03-06T13:23:45Z&se=2024-03-13T21:23:45Z&sv=2022-11-02&sr=c&sig=Uica%2B60vNO5GHGdEred2S7r9lJTzLZ%2F8fQ1GzeNs4ZM%3D',
  auth0credentials: {
    domain: 'exseede.eu.auth0.com',
    clientId: 'DHGFK7LJbWisy5LJIfmNO5JQw78Ado3F',
    authorizationParams: {
      audience: 'https://smeportal.com',
      redirect_uri: 'https://app.exseede.com',
    },
    errorPath: '/callback',
    httpInterceptor: {
      allowedList: [`https://prod-sme-portal-backend-wa.azurewebsites.net/*`],
    },
  },
  BASE_URL: "https://prod-sme-portal-backend-wa.azurewebsites.net/"
};